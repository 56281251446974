// import Vue from "vue";
// import VueRouter from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
// Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/manage/staff/list",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/login/index.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/pages/login/register.vue"),
  },
  {
    path: "/changePass",
    name: "changePass",
    component: () => import("@/pages/login/changePass.vue"),
  },
  {
    path: "/resetPage",
    name: "ResetPage",
    component: () => import("@/pages/login/resetPass.vue"),
  },
  {
    path: "/",
    name: "管理",
    component: () => import("@/pages/menu/index.vue"),
    meta: { title: "管理", login: true },
    children: [
      // 授权管理
      // {
      //   path: "/manage/authManage",
      //   component: () => import("@/pages/manage/authMange/index.vue"),
      //   meta: {
      //     title: "授权管理",
      //     icon: "qrcode",
      //     login: true,
      //     roles: "1",
      //   },
      // },
      // 员工管理
      {
        path: "/manage/staff",
        component: () => import("../App.vue"),
        meta: {
          title: "员工管理",
          icon: "idcard",
          login: true,
          roles: "2",
        },
        children: [
          {
            path: "/manage/staff/list",
            component: () =>
              import("@/pages/manage/staffManage/list.vue"),
            meta: {
              title: "员工信息",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/staff/analyze",
            component: () => import("@/pages/manage/staffManage/analyze.vue"),
            meta: {
              title: "员工分析",
              login: true,
              roles: "3",
            },
          },
        ],
      },
      // 用户管理
      {
        path: "/manage/user",
        component: () => import("../App.vue"),
        meta: {
          title: "用户管理",
          icon: "user-group",
          login: true,
          roles: "3",
        },
        children: [
          {
            path: "/manage/user/list",
            component: () => import("@/pages/manage/userManage/userList.vue"),
            meta: {
              title: "用户列表",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/user/analyze",
            component: () => import("@/pages/manage/userManage/analyze.vue"),
            meta: {
              title: "用户分析",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/user/tagList",
            component: () => import("@/pages/manage/userManage/tagList.vue"),
            meta: {
              title: "用户标签",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/user/addTag",
            component: () => import("@/pages/manage/userManage/addTag.vue"),
            meta: {
              title: "新增标签",
              login: true,
              roles: "3",
              disabled: true,
              father: "/manage/user/tagList",
            },
          },
          {
            path: "/manage/user/upload",
            component: () => import("@/pages/manage/userManage/upload.vue"),
            meta: {
              title: "用户导入",
              login: true,
              disabled: true,
              roles: "3",
              father: "/manage/user/list",
            },
          },
          {
            path: "/manage/user/detail",
            component: () => import("@/pages/manage/userManage/userDetail.vue"),
            meta: {
              title: "用户信息",
              login: true,
              disabled: true,
              roles: "3",
              father: "/manage/user/list",
            },
          },
        ],
      },
      // 订单管理
      {
        path: "/manage/order",
        component: () => import("../App.vue"),
        meta: {
          title: "订单管理",
          icon: "unordered-list",
          login: true,
          roles: "3",
        },
        children: [
          {
            path: "/manage/order/list",
            component: () => import("@/pages/manage/orderManage/list.vue"),
            meta: {
              title: "订单记录",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/order/analyze",
            component: () => import("@/pages/manage/orderManage/analyze.vue"),
            meta: {
              title: "订单分析",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/order/upload",
            component: () => import("@/pages/manage/orderManage/upload.vue"),
            meta: {
              title: "订单导入",
              login: true,
              disabled: true,
              roles: "3",
              father: "/manage/order/list",
            },
          },
        ],
      },
      // 运营设置
      {
        path: "/manage/setting",
        component: () => import("../App.vue"),
        meta: {
          title: "运营设置",
          icon: "settings",
          login: true,
          roles: "3",
        },
        children: [
          {
            path: "/manage/setting/welcome",
            component: () => import("@/pages/manage/settingManage/welcome.vue"),
            meta: {
              title: "欢迎语设置",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/setting/return",
            component: () => import("@/pages/manage/settingManage/return.vue"),
            meta: {
              title: "回访计划",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/setting/plan",
            component: () => import("@/pages/manage/settingManage/plan.vue"),
            meta: {
              title: "自定义计划",
              login: true,
              roles: "3",
              disabled: true,
              father: "/manage/setting/return",
            },
          },
          {
            path: "/manage/setting/selection",
            component: () =>
              import("@/pages/manage/settingManage/selection.vue"),
            meta: {
              title: "人群圈选",
              login: true,
              roles: "3",
            },
          },
          {
            path: "/manage/setting/addSelection",
            component: () =>
              import("@/pages/manage/settingManage/addSelection.vue"),
            meta: {
              title: "人群编辑",
              login: true,
              roles: "3",
              disabled: true,
              father: "/manage/setting/selection",
            },
          },
          {
            path: "/manage/setting/file",
            component: () => import("@/pages/manage/settingManage/fileManage.vue"),
            meta: {
              title: "素材管理",
              login: true,
              roles: "3",
            },
          },
        ],
      },
    ],
  },
];
// const createRouter = () =>
//   new VueRouter({
//     scrollBehavior: () => ({ y: 0 }),
//     routes: routes,
//   });

// const router = createRouter();
// export function resetRouter() {
//   const newRouter = createRouter();
//   console.log(newRouter);
//   router.matcher = newRouter.matcher; // reset router
//   console.log(router);
// }
// router.beforeEach((to, from, next) => {
//   console.log("to", to);
//   if (to.meta.login && !localStorage.getItem("DEMOtoken")) {
//     next({
//       path: "/login",
//     });
//   } else {
//     next();
//   }
// });
export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});
export default router;
