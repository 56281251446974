<template>
  <RouterView></RouterView>
</template>
<style>
#app {
  /* width: 100vw; */
  background-color: var(--color-bg-1);
  overflow-y: hidden;
  height: 100vh;
}
body{
  padding: 0px;
  margin: 0px;
}
.pageBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.mainBox {
  padding: 20px;
  /* background-color: #fff; */
  height: calc(100vh - 163px);
  overflow-y: auto;
}
</style>
