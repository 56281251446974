import { createApp } from 'vue'
import ArcoVue from '@arco-design/web-vue';
import App from './App.vue';
import '@arco-themes/vue-xg-scrm/index.less';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import { Message } from "@arco-design/web-vue";
import 'echarts'
import ECharts from 'vue-echarts';




import router from './router';
// import store from './store';


const app = createApp(App);
app.config.globalProperties.$message = Message;
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(router)
app.component('v-chart', ECharts)
// app.use(store)
app.mount('#app');